import {Combobox} from '@/components/Combobox';
import {Roles} from '@/stores';
import {SelectOption, Tier} from '@/types';
import {Controller, useForm} from 'react-hook-form';
import {useDashboardUsersMutation} from '@/views/dashboard/useDashboardQuery';
import {transformUserListItems, useUserListMutation, useUserListPageData} from '@/views/deals/list/useDealListQuery';

interface FilterDashboardFormProps {
    readonly tier: Tier;
    readonly setSalesAgent: (salesAgent: SelectOption<string>) => void;
    readonly refetchDealReservations?: () => void;
}

export function FilterSalesAgentDashboardForm({
    tier,
    setSalesAgent,
    refetchDealReservations,
}: FilterDashboardFormProps) {
    const { data: defaultUsers } = useUserListPageData({}, tier === 'C2C' ? [Roles.C2C_SALES_AGENT, Roles.SALES_EXTERNAL_AGENT] : Roles.SALES_AGENT);
    const { mutateAsync: filterUsers } = useUserListMutation();
    const { mutateAsync: useDashboardUsers } = useDashboardUsersMutation();

    const { control, handleSubmit, trigger } = useForm({
        mode: 'onSubmit',
    });

    const getUsers = async (inputValue: string) => {
        const data = await filterUsers({
            query: inputValue,
        });

        const users = transformUserListItems(
            data,
            {
                query: inputValue,
            },
            tier === 'C2C' ? [Roles.C2C_SALES_AGENT, Roles.SALES_EXTERNAL_AGENT] : Roles.SALES_AGENT,
        );

        return users;
    };

    const handleSubmitFilterForm = handleSubmit(async (filters, e) => {
        e?.preventDefault();

        let filteredFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value !== '' && value));
        filteredFilters = { ...filteredFilters, tier };

        if (filters.salesAgent && typeof filters.salesAgent === 'object') {
            filteredFilters.salesAgentUuid = filters.salesAgent?.value;
            delete filteredFilters.salesAgent;
        }

        useDashboardUsers(filteredFilters);
    });

    return (
        <form className="w-64 bg-gray-100 rounded-lg">
            <Controller
                name="salesAgent"
                control={control}
                render={({ field }) => (
                    <Combobox
                        label=""
                        placeholder="Selecciona un agente de ventas"
                        {...field}
                        defaultValue={undefined}
                        options={getUsers}
                        defaultOptions={defaultUsers}
                        onChange={(object: { label: string; value: string }) => {
                            field.onChange(object);
                            setSalesAgent(object);
                            trigger().then((isValid) => {
                                if (isValid) {
                                    handleSubmitFilterForm();
                                    refetchDealReservations?.();
                                }
                            });
                        }}
                    />
                )}
            />
        </form>
    );
}
